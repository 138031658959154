import React from "react"
import { Link } from "gatsby"
import { connect } from "react-redux"
import { Button } from "react-bootstrap"

import { logInUser } from "../../actions"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { expectAuthenticationState } from "../../components/authentication"
import Loading from "../../components/loading"
import ErrorApi from "../../components/error"

class OpenLinkPage extends React.Component {
  constructor() {
    super()
    this.state = {
      status: "LOADING",
    }
  }

  render() {
    switch (this.state.status) {
      case "LOADING":
        return (
          <Layout>
            <SEO title="Check login" />
            <h1>Please wait while we log you in</h1>
            <Loading />
          </Layout>
        )
      case "REJECTED":
        return (
          <Layout>
            <SEO title="Check login" />
            <h1>Sorry</h1>
            <p>The login link you{'\''}ve just opened is no longer valid</p>
            <Button as={Link} variant="secondary" to="/login" data-test>
              Request a new one
            </Button>
          </Layout>
        )
      default: {
        return (
          <Layout>
            <SEO title="Check login" />
            <ErrorApi />
          </Layout>
        )
      }
    }
  }

  async componentDidMount() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const email = urlSearchParams.get('email')
    const token = urlSearchParams.get('token')
    
    if (!token || token.length !== 32) {
      console.log('token is invalid')
      this.setState({ status: "REJECTED" })
      return
    }

    const response = await fetch("/api/login/email/callback", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, token }),
      credentials: "same-origin",
    })

    if (response.ok) {
      const result = await response.json()
      if (result.ok) {
        this.props.logInUserClick()
      } else {
        this.setState({ status: "REJECTED" })
      }
    } else {
      this.setState({ status: "ERRORED" })
    }
  }
}

function mapStateToProps(state) {
  return {
    connected: state.user.connected,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    logInUserClick: () => {
      dispatch(logInUser(true))
    },
  }
}

export default expectAuthenticationState({ connected: false })(
  connect(mapStateToProps, mapDispatchToProps)(OpenLinkPage)
)
